import { Heading } from "@chakra-ui/react"
import React, { FC } from "react"

export const PageHeading: FC = ({ children }) => {
  return (
    <Heading as="h1" size="3xl">
      {children}
    </Heading>
  )
}
