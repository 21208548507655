import React from "react"
import { Authenticated2 } from "../components/auth/Authenticated2"
import { UnAuthenticatedAlert } from "../components/auth/UnAuthenticatedAlert"
import { EnsureEmailVerifiedViaRedirect } from "../components/auth/VerifyEmail"
import { BodyWrapper } from "../components/layout/BodyWrapper"
import { Layout } from "../components/layout/Layout"
import { LoadingPage } from "../components/layout/LoadingPage"
import { Nav } from "../components/layout/Nav"
import { PageHeading } from "../components/layout/PageHeading"
import { PageTitle } from "../components/typing-test/PageTitle"

function NoAuth() {
  return (
    <Layout>
      <Nav showBottomBorder />
      <BodyWrapper>
        <EnsureEmailVerifiedViaRedirect />
        <PageHeading>Typing Test</PageHeading>
        <UnAuthenticatedAlert />
      </BodyWrapper>
    </Layout>
  )
}

export default function TypingTest() {
  return (
    <>
      <PageTitle />
      <Authenticated2 loading={<LoadingPage />} noAuth={<NoAuth />}>
        <Layout>
          <Nav showBottomBorder />
          <BodyWrapper>
            <EnsureEmailVerifiedViaRedirect />
            <PageHeading>Typing Test</PageHeading>
            <p>Typing test</p>
          </BodyWrapper>
        </Layout>
      </Authenticated2>
    </>
  )
}
