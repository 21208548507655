import { Button, Heading, Text, useToast, VStack } from "@chakra-ui/react"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { noSsr } from "../NoSsrContext"
import { useUser } from "./FirebaseContext"
import { useAuthenticated } from "./Authenticated"
import { HelpLink } from "../HelpLink"
import { config } from "../../config"
import { useMixpanel } from "gatsby-plugin-mixpanel"

function ResendEmailButton() {
  const mixpanel = useMixpanel()
  const { user } = useAuthenticated()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  async function handleResend() {
    setIsLoading(true)
    mixpanel.track("Sign up - resend verification email")
    try {
      await user.sendEmailVerification()
      toast({
        status: "success",
        title: "Email sent.",
        isClosable: true,
      })
    } catch (err) {
      // Error from Firebase
      toast({
        status: "error",
        title: "Server",
        description: err.message,
        duration: null,
        isClosable: true,
      })
    }
    setIsLoading(false)
  }

  return (
    <Button
      size="sm"
      onClick={handleResend}
      isLoading={isLoading}
      loadingText="Sending"
    >
      Resend Email
    </Button>
  )
}

export function EmailUnverified({ onVerified }: { onVerified: () => void }) {
  const { user } = useAuthenticated()

  // Triggers after user refreshes the page since user isn't updated via polling or whatever
  // to make sure the user clicked and loaded the email link with a verification page.
  useEffect(() => {
    if (user.emailVerified) onVerified()
  }, [onVerified, user])

  return (
    <VStack spacing={4} textAlign="center">
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <Text fontSize="5xl" lineHeight="1">
        📫
      </Text>
      <Heading>Check Your Inbox</Heading>
      <Text opacity={0.6}>
        Check your <b>{user.email}</b> email account inbox for an email from{" "}
        <b>"{config.SITE_NAME}"</b>.
      </Text>
      {/* <Progress size="xs" isIndeterminate width="100%" /> */}
      <div>
        Email didn't go through?&nbsp;&nbsp;
        <ResendEmailButton />
      </div>
      <Text size="xs" opacity={0.6}>
        <HelpLink />
      </Text>
      {/* <Button onClick={() => onVerified()}>Done</Button> */}
    </VStack>
  )
}

export function EmailVerified({ onDone }: { onDone: () => void }) {
  return (
    <VStack spacing={4} textAlign="center">
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <Text fontSize="5xl" lineHeight="1">
        🤝
      </Text>
      <Heading>Email Verified</Heading>
      <Text opacity={0.6}>
        Now your typing progress will be saved under your account. Nice!
      </Text>
      <Button onClick={() => onDone()} size="lg" colorScheme="blue" autoFocus>
        Start Typing!
      </Button>
    </VStack>
  )
}

export const EnsureEmailVerifiedViaRedirect = noSsr(() => {
  const { isLoading, user } = useUser()

  if (!isLoading && user && !user.emailVerified) {
    navigate("/account/verify-email")
  }

  return null
})
