import { SlideFade, VStack } from "@chakra-ui/react"
import React, { FC } from "react"
import { LayoutBody } from "./LayoutBody"

export const BodyWrapper: FC = ({ children }) => (
  // py={10} ensures that the spacing works well with the heading that also has the same spacing
  <LayoutBody size="4xl" pt={[10, 10, "8vw"]} pb={[10, 10, "8vw"]}>
    <SlideFade in>
      <VStack spacing={10} align="stretch">
        {children}
      </VStack>
    </SlideFade>
  </LayoutBody>
)
