import React from "react"
import { Layout } from "./Layout"
import { LayoutBody } from "./LayoutBody"
import { LoadingNav } from "./Nav"

export function LoadingPage() {
  return (
    <Layout>
      <LoadingNav />
      <LayoutBody>
        {/* Empty div ensures that layout body takes up full size */}
        <div />
      </LayoutBody>
    </Layout>
  )
}
